import { mesta } from "../../municipality/mesta";

/**
 * Definition of Home page.
 * 
 * @author Jiri Hynek
 */
const home = (response) => {
    return {
        id: "home",
        header: {
            image: {
                large: "loga/title/brno/large.svg",
                small: "loga/title/brno/small.svg",
            }
        },
        sections: [
            {
                id: "municipality",
                noAnchor: true,
                heading: {
                    label: "Vizualizace z hlasování Zastupitelstva města Brna",
                },
                content: {
                    id: "intro",
                    type: "municipality-card-grid",
                    config: {
                        items: [
                            mesta.brno.organy.zastupitelstvo
                        ]
                    }
                }
            }
        ]
    }
}
export default home;