export const mesta = {
    brno: {
        id: 1,
        text_id: "brno",
        typ_nazev: "Statutární město",
        rada: false,
        nazev: "Brno",
        nazev_genitiv: "Brna",
        logo: "flask/municipality/brno/logo",
        barva: "#da2128",
        url: "https://www.brno.cz/",
        deploy: {
            local: {
                subdomain: "brno",
                path: ""
            }
        },
        email: "data@brno.cz",
        partner: true,
        url: "https://www.brno.cz/",
        uplnyDataset: true,
        organy: {
            zastupitelstvo: {
                nazev_aplikace: "Vizualizace hlasování Zastupitelstva města Brna",
                nazev: "Zastupitelstvo města Brna",
                nazev_genitiv: "Zastupitelstva města Brna",
                nazev_kratky: "Zastupitelstvo",
                misto: "v zasedací síni Nové radnice",
                pocet_zastupitelu: 55,
                popis_aplikace: "Přehledné vizualizace z 8. a 9. Zastupitelstva města Brna pro širokou veřejnost.",
                deploy: {
                    local: {
                        subdomain: "brno",
                        path: "brno/zastupitelstvo/9/"
                    }
                },
                varianty: {
                    2018: {
                        nazev: "Brno: zastupitelstvo 2018-2022",
                        urlDataset: "https://brno.zastupko.cz/flask/brno/zastupitelstvo/8/dataset",
                        urlHlasovaciData: "https://www.brno.cz/zasedani-zmb",
                        posledniAktualizace: "2024-08-29",
                        uplnyDataset: true,
                    },
                    2019: {
                        nazev: "Brno: zastupitelstvo 2022-2024",
                        urlDataset: "https://brno.zastupko.cz/flask/brno/zastupitelstvo/9/dataset",
                        urlHlasovaciData: "https://www.brno.cz/zasedani-zmb",
                        posledniAktualizace: "2024-08-29",
                        uplnyDataset: true,
                    }
                }
            },
        }
    },
    most: {
        id: 2,
        text_id: "most",
        typ_nazev: "Statutární město",
        rada: true,
        nazev: "Most",
        nazev_genitiv: "Mostu",
        logo: "flask/municipality/most/logo",
        barva: "#415BC7",
        url: "https://www.mesto-most.cz/",
        deploy: {
            local: {
                subdomain: "most",
                path: ""
            }
        },
        email: "jana.hesterini@mesto-most.cz",
        partner: true,
        uplnyDataset: true,
        organy: {
            zastupitelstvo: {
                nazev_aplikace: "Vizualizace hlasování Zastupitelstva města Mostu",
                nazev: "Zastupitelstvo města Mostu",
                nazev_genitiv: "Zastupitelstva města Mostu",
                nazev_kratky: "Zastupitelstvo",
                misto: "v budově Magistrátu města Mostu",
                pocet_zastupitelu: 45,
                popis_aplikace: "Přehledné vizualizace ze 7. a 8. Zastupitelstva města Mostu pro širokou veřejnost.",
                deploy: {
                    local: {
                        subdomain: "most",
                        path: "most/zastupitelstvo/8/"
                    }
                },
                varianty: {
                    zastupitelstvo2018: {
                        nazev: "Most: zastupitelstvo 2018-2022",
                        urlDataset: "https://most.zastupko.cz/flask/most/zastupitelstvo/7/dataset",
                        urlHlasovaciData: "https://data.gov.cz/datov%C3%A1-sada?iri=https%3A%2F%2Fdata.gov.cz%2Fzdroj%2Fdatov%C3%A9-sady%2F00266094%2F7377890592f373560c79170f3c1aa05a",
                        posledniAktualizace: "2024-08-29",
                        uplnyDataset: true,
                    },
                    zastupitelstvo2022: {
                        nazev: "Most: zastupitelstvo 2022-2026",
                        urlDataset: "https://most.zastupko.cz/flask/most/zastupitelstvo/8/dataset",
                        urlHlasovaciData: "https://data.gov.cz/datov%C3%A1-sada?iri=https%3A%2F%2Fdata.gov.cz%2Fzdroj%2Fdatov%C3%A9-sady%2F00266094%2F7377890592f373560c79170f3c1aa05a",
                        posledniAktualizace: "2024-08-29",
                        uplnyDataset: true,
                    }
                }
            },
            rada: {
                nazev_aplikace: "Vizualizace hlasování Rady města Mostu",
                nazev: "Rada města Mostu",
                nazev_genitiv: "Rady města Mostu",
                nazev_kratky: "Rada",
                misto: null,
                pocet_zastupitelu: 9,
                popis_aplikace: "Přehledné vizualizace ze 7. a 8. Rady města Mostu pro širokou veřejnost.",
                deploy: {
                    local: {
                        subdomain: "most",
                        path: "most/rada/8/"
                    }
                },
                varianty: {
                    rada2022: {
                        nazev: "Most: rada 2022-2026",
                        urlDataset: "https://most.zastupko.cz/flask/most/rada/8/dataset",
                        urlHlasovaciData: "https://data.gov.cz/datov%C3%A1-sada?iri=https%3A%2F%2Fdata.gov.cz%2Fzdroj%2Fdatov%C3%A9-sady%2F00266094%2Fc07f98ca87e75d25061b0552a37c55f8",
                        posledniAktualizace: "2024-08-29",
                        uplnyDataset: true,
                    }
                }
            }
        }
    },
    praha: {
        id: 3,
        text_id: "praha",
        typ_nazev: "Hlavní město",
        rada: false,
        nazev: "Praha",
        nazev_genitiv: "Prahy",
        logo: "flask/municipality/praha/logo",
        barva: "#C81428",
        deploy: {
            local: {
                subdomain: "praha",
                path: ""
            }
        },
        email: "",
        partner: false,
        url: "https://www.praha.eu/",
        uplnyDataset: true,
        organy: {
            zastupitelstvo: {
                nazev_aplikace: "Vizualizace hlasování Zastupitelstva hlavního města Prahy",
                nazev: "Zastupitelstvo hlavního města Prahy",
                nazev_genitiv: "Zastupitelstva hlavního města Prahy",
                nazev_kratky: "Zastupitelstvo",
                misto: null,
                pocet_zastupitelu: 65,
                popis_aplikace: "Přehledné vizualizace z 9. Zastupitelstva hlavního města Prahy pro širokou veřejnost.",
                deploy: {
                    local: {
                        subdomain: "praha",
                        path: "praha/zastupitelstvo/9/"
                    }
                },
                varianty: {
                    zastupitelstvo2012: {
                        nazev: "Praha: zastupitelstvo 2022-2026",
                        urlDataset: "https://praha.zastupko.cz/flask/praha/zastupitelstvo/9/dataset",
                        urlHlasovaciData: "https://data.gov.cz/datov%C3%A9-sady?poskytovatel=https%3A%2F%2Frpp-opendata.egon.gov.cz%2Fodrpp%2Fzdroj%2Forg%C3%A1n-ve%C5%99ejn%C3%A9-moci%2F00064581&kl%C3%AD%C4%8Dov%C3%A1-slova=zastupitelstvo&kl%C3%AD%C4%8Dov%C3%A1-slova=hlasov%C3%A1n%C3%AD",
                        posledniAktualizace: "2024-08-29",
                        uplnyDataset: true,
                    }
                }
            }
        }
    }
}

export const mestaList = [
    mesta.brno,
    mesta.most,
    mesta.praha
]