import config_zastupko from "./config/zastupko/config";

const getSubdomain = (subdomain) => {
    //return process.env.REACT_APP_DOMAIN;
    return subdomain;
}

/*const getHostnameApp = () => {
    const hostname = window.location.hostname;

    const hostnameParts = hostname.split('.');
    
    const app = {
        domain: undefined,
        subdomain: undefined,
        logo: "/loga/title/zastupko/small.svg"
    };
    if(hostnameParts[hostnameParts.length - 1] === 'localhost') {
        app.domain = 'localhost';
        app.url = 'http://localhost:3000';
        if(hostnameParts.length > 1) {
            app.subdomain = getSubdomain(hostnameParts[hostnameParts.length - 2]);
        }
    } else {
        app.domain = hostnameParts[hostnameParts.length - 2];
        app.url = 'https://zastupko.cz';
        if(hostnameParts.length > 2) {
            app.subdomain = getSubdomain(hostnameParts[hostnameParts.length - 3]);
        }
    }

    return app;
}*/

const getAppConfig = () => {

    
    // get config type from env
    // TODO: config should be get from database
    let rootConfig = {
        configType: process.env.REACT_APP_CONFIG,
        protocol: process.env.REACT_APP_PROTOCOL,
        port: process.env.REACT_APP_PORT,
        apiDomain: process.env.REACT_APP_API_DOMAIN,
        apiPort: process.env.REACT_APP_API_PORT,
        apiPath: process.env.REACT_APP_API_PATH,
        apiProtocol: process.env.REACT_APP_API_PROTOCOL,
    };
    let domainConfig = {};

    const domain = process.env.REACT_APP_DOMAIN;
    const hostname = window.location.hostname;
    if(hostname.endsWith(domain)) {

        const subdomain = hostname.substring(0, hostname.length - domain.length - 1);

        getSubdomain(subdomain);

        switch(rootConfig.configType) {
            case "zastupko":
                domainConfig = config_zastupko(subdomain);
            // todo local deploy for most, brno, ...
        }

        rootConfig = {
            hostname: hostname,
            domain: domain,
            subdomain: subdomain,
            ...domainConfig,
            ...rootConfig
        };
    }

    return rootConfig;
}

export const getApp = () => {
    return getAppConfig();
}

const APP = getApp();
export default APP;