import React from "react";

import "./MainFooter.css"
import APP from "../../config/config";

function MainFooter({
}) {

    return (
        <>
            
            <footer className="main-footer">
                <div className="main-footer-wrapper">
                    <div className="main-footer-logo">
                        <img src="/loga/title/zastupko/small.svg"></img>
                        <p>Vizualizace hlasování<br/> českých zastupitelstev</p>
                    </div>
                    <div className="main-footer-projects">
                        <h3>Projekty</h3>
                        <ul>
                            <li><a href={`${APP.protocol}://kraje.${APP.domain}${APP.port && ':' + APP.port}`}>{`kraje.${APP.domain}`}</a></li>
                            <li><a href={`${APP.protocol}://mesta.${APP.domain}${APP.port && ':' + APP.port}`}>{`mesta.${APP.domain}`}</a></li>
                            <li><a href={`${APP.protocol}://brno.${APP.domain}${APP.port && ':' + APP.port}`}>{`brno.${APP.domain}`}</a></li>
                            <li><a href={`${APP.protocol}://most.${APP.domain}${APP.port && ':' + APP.port}`}>{`most.${APP.domain}`}</a></li>
                        </ul>
                    </div>
                    <div className="main-footer-pages">
                        <h3>Užitečné odkazy</h3>
                        <ul>
                            <li><a href={`${APP.protocol}://${APP.domain}${APP.port && ':' + APP.port}/analyzy`}>{`Analýzy`}</a></li>
                            <li><a href={`${APP.protocol}://${APP.domain}${APP.port && ':' + APP.port}/datasety`}>{`Datasety`}</a></li>
                            <li><a href={`${APP.protocol}://${APP.domain}${APP.port && ':' + APP.port}/spoluprace`}>{`Spolupráce`}</a></li>
                            <li><a href={`${APP.protocol}://${APP.domain}${APP.port && ':' + APP.port}/o-projektu`}>{`O projektu`}</a></li>
                        </ul>
                    </div>
                    <div className="main-footer-contacts">
                        <h3>Kontakty</h3>
                        <p>
                            <a href={`https://dexter.fit.vutbr.cz`} target="_blank" rel="noreferrer">{`dexter.fit.vutbr.cz`}</a>
                            <a href="mailto:zastupko@zastupko.cz"><i className="pi pi-envelope"></i></a>
                            <a href="https://www.facebook.com/zastupko.cz"><i className="pi pi-facebook"></i></a>
                        </p>
                        <a href="https://www.fit.vut.cz" target="_blank" rel="noreferrer"><img src="/loga/logo_fit.png"></img></a>
                    </div>
                    <div className="main-footer-note">
                        <div>Projekt je v průběžném vývoji. Informace na webu mohou obsahovat chyby a mohou být změněny bez upozornění. V případě dotazů, připomínek, či jakýchkoliv nepřesností nám neváhejte poslat <a href="mailto:zastupko@zastupko.cz">zprávu</a>.</div>
                        <div>Zastupko.cz, 2024</div>
                    </div>
                    
                </div>
            </footer>
        </>
    )
}

export default MainFooter;