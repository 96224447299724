import React, { useEffect, useState } from 'react';
import { NavLink, useParams, Link, useLocation } from 'react-router-dom';
import { Sidebar, Menubar, Button, Menu } from './PrimereactComponents';
import { getBodyNameGenitive } from '../utils/texts';
import useDimensions from '../hooks/useDimensions';
import { useTranslation } from 'react-i18next';
import LanguageSelection from './LanguageSelection';
import { logoUrl } from '../utils/logoUrl';
import stats from '../config/stats/stats';
import axios from 'axios';
import APP, { getApp } from '../config/config';

import './Menu2.css';
import { getData } from '../config/data/data';

function NavMenu2() {
    
    const { municipalitaId, bodyType, zastupitelstvoId } = useParams();
    const detailVersion = bodyType !== undefined;
    
    const [ data, setData ] = useState({
        municipality: {},
        councils: [],
        basicData: {},
        data: {}
    });

    const [rightSidebarVisible, setRightSidebarVisible] = useState(false);
    const [mainVisible, setMainVisible] = useState(false);

    const { t } = useTranslation();

    const fetchMunicipalityData = async () => {
        const [municipalityResponse, councilsResponse, basicData] = await Promise.all([
            axios.get(`municipality/${municipalitaId}`),
            axios.get(`${municipalitaId}/${bodyType}/councils`),
            axios.get(`${municipalitaId}/${bodyType}/${zastupitelstvoId}/generalData`)
        ]);

        const data = getData()[municipalityResponse.data.text_id] ?? getData()[bodyType]

        setData({
            municipality: municipalityResponse.data,
            councils: councilsResponse.data,
            basicData: basicData.data,
            data: municipalityResponse?.data?.text_id ? data : undefined
        });
    };
    
    const location = useLocation();
    useEffect(() => {
        if (detailVersion) {
            fetchMunicipalityData();
        }
    }, [location]);

    // NavLink template
    const temp = (item, options) => {
        return (
            <NavLink
                to={item.url}
                className={({ isActive }) =>
                    isActive ? `menu__link-active menu-item ${options.className}` : `${options.className} menu-item`
                }
                onClick={() => setMainVisible(false)}
                end
            >
                <span className={`${item.icon}`}></span>&nbsp;
                <span>{item.label}</span>
            </NavLink>
        );
    };

    // TODO: This is a hardcoded solution. It should be set externally.
    const rootNavItems = [];

    const currentApp = getApp();

    // Subdomain
    currentApp.subdomain && rootNavItems.push({
        label: currentApp.subdomain.charAt(0).toUpperCase() + currentApp.subdomain.slice(1),
        icon: 'pi pi-arrow-right',
        url: '/',
        template: temp
    });

    // Municipality
    if(detailVersion) {
        currentApp.subdomain && rootNavItems.push({
            label: data.data?.nazev_kratky ?? data.data?.nazev,
            icon: 'pi pi-arrow-right',
            url: `/${municipalitaId}/${bodyType}/${zastupitelstvoId}/`,
            template: temp
        });

        rootNavItems.push({
            label: 'Subjekty',
            icon: 'pi pi-fw pi-users',
            url: `/${municipalitaId}/${bodyType}/${zastupitelstvoId}/subjekty`,
            template: temp
        });
        
        rootNavItems.push({
            label: 'Zastupitelé',
            icon: 'pi pi-fw pi-user',
            url: `/${municipalitaId}/${bodyType}/${zastupitelstvoId}/zastupitele`,
            template: temp
        });

        /**
         * additional
         */
        if (data.basicData?.nejnovejsi_zasedani?.id) {
            rootNavItems.push({
                    label: t('Meetings'),
                    icon: 'pi pi-fw pi-book',
                    url: `/${municipalitaId}/${bodyType}/${zastupitelstvoId}/zasedani`,
                    template: temp
                },
                {
                    label: t('Votings'),
                    icon: 'pi pi-fw pi-check-square',
                    url: `/${municipalitaId}/${bodyType}/${zastupitelstvoId}/hlasovani`,
                    template: temp
                },
                {
                    label: t('Analyses'),
                    icon: 'pi pi-fw pi-chart-line',
                    //url: `/${municipalitaId}/${bodyType}/${zastupitelstvoId}/analyzy`,
                    items: [
                        {
                            label: 'Docházka',
                            url: `/${municipalitaId}/${bodyType}/${zastupitelstvoId}/analyzy/dochazka`,
                            template: temp
                        },
                        {
                            label: 'Porovnání zastupitelů',
                            url: `/${municipalitaId}/${bodyType}/${zastupitelstvoId}/analyzy/porovnani_zastupitelu`,
                            template: temp
                        },
                        {
                            label: 'Nejednotnost stran',
                            url: `/${municipalitaId}/${bodyType}/${zastupitelstvoId}/analyzy/nejednotnost`,
                            template: temp
                        },
                        {
                            label: 'Koheze hlasování',
                            url: `/${municipalitaId}/${bodyType}/${zastupitelstvoId}/analyzy/koheze`,
                            template: temp
                        }
                    ],
                    className: 'menu-item'
                    //template: temp,
                }
            );
        }
    } else {
        // Compairing stats
        stats(undefined) !== undefined && rootNavItems.push({
            label: detailVersion ? 'Srovnání' :'Analýzy',
            icon: 'pi pi-chart-bar',
            url: '/analyzy',
            template: temp
        });

        rootNavItems.push({
            label: 'Datasety',
            icon: 'pi pi-database',
            url: '/datasety',
            template: temp
        });

        rootNavItems.push({
            label: 'Spolupráce',
            icon: 'pi pi-users',
            url: '/spoluprace',
            template: temp
        });

        rootNavItems.push({
            label: t('About'),
            icon: 'pi pi-fw pi-info-circle',
            url: `/o-projektu`,
            template: temp
        });
    }

    const bodyNameGenitive = getBodyNameGenitive(bodyType) || undefined;
    
    const getRootUrl = () => {
        const portSuffix = currentApp.port ? `:${currentApp.port}` : '';

        if(currentApp.subdomain && currentApp.subdomain !== "") {
            return `${currentApp.protocol}://${currentApp.domain}${portSuffix}`;
        } else {
            return '/';
        }
    }

    // Menu item on left
    const start =
        (
            <div className="menu-start">
                <button
                    onClick={() => setMainVisible(!mainVisible)}
                    type='button'
                    className='menu-start-hamburger p-sidebar-close p-sidebar-icon p-link p-link'
                    aria-label='Open'
                >
                    <span className='p-sidebar-close-icon pi pi-bars' aria-hidden='true'></span>
                </button>
                <a className="menu-start-logo" href={getRootUrl()}>
                    <img src={currentApp.logo} alt="Zastupko.cz" />
                </a>
            </div>
        );

    let currentCouncil;

    if (detailVersion && data.councils) {
        currentCouncil = data.councils.find((item) => item && item?.poradi === parseInt(zastupitelstvoId, 10));
    }

    // Menu item on right
    const end = (
        <>
            {
                detailVersion && (
                    <Button
                        style={{ backgroundColor: data.municipality.barva }}
                        className='mainmenu__button'
                        onClick={() =>setRightSidebarVisible(!rightSidebarVisible)}
                        disabled={data.councils.length < 2}>
                        {
                            data.councils.length && currentCouncil
                            ? `${currentCouncil.funkcni_obdobi_od}-${currentCouncil.funkcni_obdobi_do}`
                            : `${t('selection')} ${bodyNameGenitive}`
                        }
                    </Button>
            )}
            <LanguageSelection />
        </>
    );
    return (
        <div className='mainmenu'>
            <Menubar model={rootNavItems} start={start} end={end} />

            <Sidebar visible={mainVisible} position='left' onHide={() => setMainVisible(false)}>
                <Menu style={{ width: '100%', border: 'none' }} model={rootNavItems} />
            </Sidebar>
            
            {bodyNameGenitive && data.councils && (
                <Sidebar visible={rightSidebarVisible} position='right' onHide={() => setRightSidebarVisible(false)}>
                    <h2 className='sidemenu__title'>{t('selection', { context: bodyNameGenitive })}</h2>
                    <nav className='sidemenu__nav-flex'>
                        {data.councils
                            .sort((a, b) => (a.poradi < b.poradi ? 1 : -1))
                            .map((council) => (
                                <NavLink
                                    key={`${council.poradi}`}
                                    to={`/${municipalitaId}/${bodyType}/${council.poradi}/`}
                                    onClick={() => setRightSidebarVisible(false)}
                                >
                                    <span
                                        className={
                                            zastupitelstvoId === council.poradi.toString()
                                                ? 'sidemenu__nav-link-active'
                                                : 'sidemenu__nav-link'
                                        }
                                    >
                                        funkční období {council.funkcni_obdobi_od}-{council.funkcni_obdobi_do}
                                    </span>
                                </NavLink>
                            ))}
                    </nav>
                </Sidebar>
            )}
        </div>
    );
}

export default NavMenu2;
